import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["lab", "employeeGroup", "user"];

  connect() {
    if (typeof this.userTarget.tomselect === "undefined") {
      setTimeout(() => {
        this.connect();
      }, 100);
    } else {
      this.toggleInput(this.employeeGroupTarget, false);
      this.toggleInput(this.userTarget, false);
      this.updateInputStates();
      this.updateEmployeeGroups();
      this.restoreSelectedUsers();
    }
  }

  restoreSelectedUsers() {
    const selectedUserIds = JSON.parse(this.userTarget.dataset.selectedUserIds || "[]");
    selectedUserIds.forEach(userId => {
      this.userTarget.tomselect.addItem(userId, true); // true ensures the item is not re-rendered
    });
  }

  updateInputStates() {
    const isLabSelected = this.labTarget.value != "";
    const isEmployeeGroupSelected = this.employeeGroupTarget.value != "";

    this.toggleInput(this.employeeGroupTarget, isLabSelected);
    this.toggleInput(this.userTarget, isLabSelected && isEmployeeGroupSelected);
  }

  updateUsers() {
    this.updateInputStates();

    if (this.employeeGroupTarget.value != "") {
      this.fetchAndUpdate(this.userTarget, `resource_id=${this.employeeGroupTarget.value}&filter_resource_type=employee_group`);
    }
  }

  updateEmployeeGroups() {
    this.updateInputStates();

    if (this.labTarget.value != "") {
      this.fetchAndUpdate(this.employeeGroupTarget, `filter_resource_type=lab`);
    }
  }

  toggleInput(input, condition) {
    condition ? input.tomselect.enable() : input.tomselect.disable();
  }

  fetchAndUpdate(target, additionalParams = '') {
    fetch(`list_staff_resources_by_lab?id=${this.labTarget.value}&selectbox_items=true&${additionalParams}`)
      .then(response => response.json())
      .then(data => this.updateInput(target.tomselect, data['resources']));
  }

  updateInput(input, data) {
    var selectedValue;
    if(input.getValue() != null) {
      selectedValue = input.getValue();
    }
    input.clear();
    input.clearOptions();
    input.addOptions(data);
    input.refreshItems();
    input.setValue(selectedValue);
  }
}
