import {Controller} from "@hotwired/stimulus";

// Connects to data-controller="service-call"
export default class extends Controller {
  static targets = ["device", "model", "brand", "user", "toggleable", "transferLink"];

  // #TODO: :Refactor with turbo.
  get companyId() {
    return this.element.dataset.companyId;
  }

  connect() {
    this.update();
  }

  update() {
    let selectedDevice = this.deviceTarget.value;

    if (selectedDevice === "") {
      this.modelTarget.value = "";
      this.brandTarget.value = "";
      this.userTarget.innerHTML = "";
    } else {
      this.fetchDeviceCard(selectedDevice);
      this.fetchUsers(selectedDevice);
    }
  }

  clear() {
    this.userTarget.tomselect.clear();
    this.userTarget.tomselect.clearOptions();
    this.userTarget.innerHTML = '';
  }

  fetchDeviceCard(selectedDevice) {
    let url = this.fetchUrl('DeviceCard', selectedDevice);

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (this.hasModelTarget && this.hasBrandTarget) {
          this.populateDeviceCard(data[0].model, this.modelTarget);
          this.populateDeviceCard(data[0].brand, this.brandTarget);
        }
      });
  }

  fetchUsers(selectedDevice) {
    let url = this.fetchUrl('DeviceCardExternalUser', selectedDevice);

    fetch(url)
      .then(response => response.json())
      .then(data => {
        this.updateUserInput(data);
        this.appendCurrentUserValue();
      });
  }

  populateDeviceCard(value, input) {
    input.value = value;
  }

  updateUserInput(users) {
    if (this.userTarget.tomselect) {
      this.clear();
      this.userTarget.tomselect.addOptions(users);
      this.userTarget.tomselect.refreshOptions();
      this.userTarget.tomselect.refreshItems();
  
      if (users.length === 1) {
        setTimeout(() => {
          if (this.userTarget.tomselect && this.userTarget.tomselect.setValue) {
            this.userTarget.tomselect.setValue(users[0].value, true);
          }
        }, 0);
      }
    } else {
      const uniqueUsers = Array.from(new Set(users.map((user) => JSON.stringify(user)))).map((str) => JSON.parse(str));
      this.userTarget.innerHTML = '';
      uniqueUsers.forEach((user) => {
        let isSelected = user.value.toString() === this.userTarget.dataset.userId || users.length === 1;
        let option = new Option(user.text, user.value, isSelected, isSelected);
        this.userTarget.appendChild(option);
      });
      this.updateTarget(this.userTarget);
      this.updateTransferLink();
    }
  
    if (users.length === 1) {
      this.userTarget.classList.add('ts-bg-zinc-100', 'pointer-events-none');
    } else {
      this.userTarget.classList.remove('ts-bg-zinc-100', 'pointer-events-none');
    }
  
    if (this.userTarget.tomselect) {
      const wrapper = this.userTarget.tomselect.wrapper;
      if (users.length === 1) {
        wrapper.classList.add('ts-bg-zinc-100', 'pointer-events-none');
      } else {
        wrapper.classList.remove('ts-bg-zinc-100', 'pointer-events-none');
      }
    }
  }

  appendCurrentUserValue() {
    if (this.hasUserTarget && this.userTarget.tomselect !== undefined && this.userTarget.dataset.userId !== '') {
      this.userTarget.tomselect.setValue(this.userTarget.dataset.userId);
    }
  }

  fetchUrl(resource_type, object_id) {
    const path = window.location.pathname;
    const match = path.match(/\/support_requests\/(\d+)/);
    const supportRequestId = match ? match[1] : null;

    let url = `/support_requests/service_call_resources?resource_type=${resource_type}&object_id=${object_id}`;

    if (supportRequestId) {
      url += `&support_request_id=${supportRequestId}`;
    }

    return url;
  }

  updateTransferLink() {
    const receiverId = this.userTarget.value;
    if (receiverId) {
      const newHref = `${this.transferLinkTarget.dataset.originalHref}?service_call_page=true&receiver_id=${receiverId}`;
      this.transferLinkTarget.setAttribute('href', newHref);
    }
  }

  updateTarget(target) {
    const updatedTarget = target.cloneNode(true);
    target.replaceWith(updatedTarget);
  }

}
