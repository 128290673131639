import { TabulatorFull as Tabulator } from 'tabulator-tables';

export function extendFormatters() {
  Tabulator.extendModule("format", "formatters", {
    numberWithHtmlFormatter: (cell, formatterParams, onRendered) => {
      const value = cell.getValue();
      return value && typeof value === 'object' && value.display ? value.display : '-';
    },
    rowNumTotal: (cell) => {
      const table = cell.getTable();
      const pageSize = table.getPageSize();
      const page = table.getPage();
      const indexOnPage = cell.getRow().getPosition(true);
      return (page - 1) * pageSize + indexOnPage;
    }
  });
}