import {Controller} from "@hotwired/stimulus"
import {sendTurboStreamRequest} from "../helpers/turbo_stream_helper";
import {buildHeaders, buildRequest, buildUrl} from "../helpers/build_request";

// Connects to data-controller="barcode-scan"
export default class extends Controller {
  static targets = [
    "input",
    "overlay",
    "stockCard",
    "quantity",
    "qrScanned",
    "templateElement",
    "quantityCheckedIn",
    "consumptionPoint",
    "emptyBody",
    "turboFrameTag"
  ];

  static values = {
    url: String, // Scan action placed in barcode_formats_controller.rb
    turboUrl: String, // Turbo request, tailored to the needs of the form
    basedOnStockIdLotExpire: Boolean, // Specifies the type of form
    nestedTable: Boolean, // Table can grow, which means that elements can be added, elements can be removed.
    turboFrameOnScan: Boolean, // Indicates whether to perform a Turbo frame request when a scan occurs
    turboFrameOnScanUrl: String, // Turbo Frame URL
  };

  connect() {
    this.clearAndToggleInput();
  }

  scan() {
    const barcodeInput = this.inputTarget.value;
    this.fetchStockCard(barcodeInput);
  }

  fetchStockCard(barcodeInput) {
    const barcodeScanUrl = buildUrl(this.urlValue, {barcode: barcodeInput});
    fetch(barcodeScanUrl)
      .then(response => Promise.all([response.ok, response.json()]))
      .then(([ok, data]) => {
        if (ok) {
          let request;
          if (this.nestedTableValue) {
            request = this.buildNestedTableTurboRequest(data);
          } else {
            request = this.buildStaticTableTurboRequest(data);
          }
          this.sendTurboStream(request);
          if (this.turboFrameOnScanValue) {
            this.initiateTurboFrame(data);
          }
        } else {
          this.notify();
        }
      });

    this.inputTarget.value = '';
  }

  notify() {
    $notification({text: this.inputTarget.dataset.notify, variant: 'error'})
  }

  buildStaticTableTurboRequest(data) {
    const quantity = Number(this.quantityTarget.value) + 1;
    const barcodeScanned = true;
    const consumptionPointId = this.consumptionPointTarget.tomselect.getValue();

    const params = {
      consumption_point_id: consumptionPointId,
      id: data.stock_card.id,
      lot: data.lot,
      expiry_date: data.expiry_date,
      quantity: quantity,
      barcode_scanned: barcodeScanned
    };
    const turboUrl = buildUrl(this.turboUrlValue, params);
    return buildRequest(turboUrl);
  }

  // Direct Transfer, Stock Entry
  // This type of table can grow, which means that elements can be added, elements can be removed.
  buildNestedTableTurboRequest(data) {
    let stockCardCompositeIdentifier = data.stock_card_composite_identifier;
    let stockCardPrimaryIdentifier = data.stock_card_primary_identifier;
    let targetStockCard = this.basedOnStockIdLotExpireValue ? stockCardCompositeIdentifier : stockCardPrimaryIdentifier;
    let targetStockCardQuantityCheckedIn = `${targetStockCard}_quantity_checked_in`;
    let targetStockCardQuantity = `${stockCardCompositeIdentifier}_quantity`;

    let stockCardExistsInDocument;
    let turboAction = 'replace';
    let quantity = 0;
    let totalCheckedInQuantity = 1;

    // Does the document include any stock cards
    if (this.hasStockCardTarget) {
      stockCardExistsInDocument = this.stockCardTargets.some(el => el.id === targetStockCard);

      // Is the stock card found with the new qr on the page
      if (stockCardExistsInDocument) {
        quantity = this.quantityTargets.find(el => el.id === targetStockCardQuantity)?.value || 0;

        // Whether document stock cards identifier is id or id_lot_expire combination
        // If identifier is id (stockCardPrimaryIdentifier) just update quantity and calculate sum
        if (!this.basedOnStockIdLotExpireValue) {
          turboAction = 'update'; // Just update the quantity targets
          totalCheckedInQuantity = this.calculateTotalCheckedInQuantity(data.stock_card.id);
        }

      } else {
        // If there is no element in document body -even template-, prepend it to the top of table.
        if (this.hasEmptyBodyTarget) {
          targetStockCard = this.emptyBodyTarget.id;
          turboAction = 'prepend';
        } else {
          targetStockCard = this.stockCardTargets.slice(-1)[0].id;
          turboAction = 'after';
        }
      }
    } else if (this.hasTemplateElementTarget) {
      // First qr scan on the page
      targetStockCard = this.templateElementTarget.id;
    } else {
      // First qr scan empty body page
      turboAction = 'append';
      targetStockCard = this.emptyBodyTarget.id;
    }

    const params = {
      id: data.stock_card.id,
      lot: data.lot,
      expiry_date: data.expiry_date,
      child_index: Date.now(),
      quantity: quantity,
      total_quantity_checked_in: totalCheckedInQuantity,
      barcode: data.barcode
    };

    const headers = {
      'Turbo-Action': turboAction,
      'Turbo-Stock-Card-Target': targetStockCard,
      'Turbo-Stock-Card-Quantity-Target': targetStockCardQuantity,
      'Turbo-Stock-Card-Quantity-CheckedIn-Target': targetStockCardQuantityCheckedIn
    };


    const url = buildUrl(this.turboUrlValue, params);
    const turboHeaders = buildHeaders(headers);

    return buildRequest(url, turboHeaders);
  }

  calculateTotalCheckedInQuantity(stockCardId) {
    return this.quantityTargets.filter(el => el.id.includes(stockCardId)).reduce((total, target) => total + (target.value ? parseInt(target.value) : 0), 1);
  }

  sendTurboStream(request) {
    sendTurboStreamRequest(request);
    this.focusElements();
  }

  focusElements() {
    this.inputTarget.value = '';
    this.inputTarget.focus();
  }

  toggleOverlayAndAddEvents() {
    this.clearAndToggleInput();
    this.inputTarget.focus();
    this.overlayTarget.classList.toggle('hidden');
    this.addInputEvent();
  }

  blockSubmit(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.scan();
    }
  }

  addInputEvent() {
    this.inputTarget.addEventListener('focusout', this.handleFocusOut.bind(this));
  }

  handleFocusOut(event) {
    if (this.overlayTarget.classList.contains('hidden')) {
      this.inputTarget.removeEventListener('focusout', this.handleFocusOut);
    } else {
      event.preventDefault();
      this.inputTarget.focus();
    }
  }

  clearAndToggleInput() {
    if (this.hasInputTarget) {
      this.inputTarget.value = '';
      this.inputTarget.classList.toggle('hidden');
    }
  }

  initiateTurboFrame(data) {
    const params = {
      stock_card_id: data.stock_card.id,
      lot: data.lot,
      expire_date: data.expiry_date,
    };

    this.turboFrameTagTarget.src = buildUrl(this.turboFrameOnScanUrlValue, params);
    this.toggleOverlayAndAddEvents();
  }
}

